@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&display=swap');

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    font-family: 'Inter';
    overflow: hidden;
}

body {
    margin: 0;
    overflow: hidden;
}

.full-height {
    height: 100dvh;
}

.content {
    top: 0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100dvh;
    overflow: hidden;
}

.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('../../src/assets/img/bg-mobile.png') center center no-repeat;
    background-size: cover;
    z-index: 1;
    /* opacity: 0.5; */
}

.background-tree {
    background: url('../../src/assets/img/bg-tree-mobile.png') center center no-repeat;
    background-size: 70%;
    width: 100%;
    height: 120px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: -1;
}

.main {
    position: relative;
    top: 63%;
    transform: translateY(-50%);
    width: 80%;
    max-width: 480px;
    margin: 0 auto;
    z-index: 2;
}
.main button {
    background: none;
    border: none;    
}
.plates,
.actions {
    position: relative;
    display: flex;
    align-items: center;
}
.plate-1,
.plate-2 {
    position: relative;
}
.days,
.hours {
    color: #FFE4AE;
    font-weight: bold;
    font-size: 24px;
    position: absolute;   
    top: 8px;
    left: 18%; 
    text-shadow: 1px 2px 1px #4e2600;
}
.days {
    transform: rotate(3deg);
}

.actions {
    position: absolute;
    bottom: 10px;
    z-index: 2;
    flex-direction: column;
}
.actions .text {
    font-size: 7px;
    color: #FFF;
    margin: 0;
    padding: 0;
    opacity: 0.4;
}
.actions button {
    background: none;
    border: none;
}

.eggmon-logo {
    position: absolute;
    top: 20%;
    transform: translate(-50%, -50%);
    -webkit-animation: float 6s ease-in-out infinite;
    animation: float 6s ease-in-out infinite;
    z-index: 4;
}

.eggmon-logo img {
    width: 60%;
}
.leaf {
    width: 35px;
    height: 35px; 
    background-size: contain;
}

.egg {
    width: 60px;
    height: 75px;
    background-size: cover;
    background-position: center;
    position: absolute;
    top: 40px;
    transform-origin: center;
    transition: opacity 0.5s ease-in-out;
}

/* .egg {
    opacity: 0;
    transition: opacity 0.5s ease-in-out;
    position: absolute;
} */

.egg.visible {
    opacity: 1;
    animation: move 5s ease-in-out infinite, rotate 5s cubic-bezier(0.25, 0.1, 0.75, 1) infinite;
}

@keyframes move {
    0% {
        left: -120px;
    }
    50% {
        left: calc(50vw - 50px);
    }
    100% {
        left: 100vw;
    }
}

@keyframes rotate {
    0% {
        transform: rotate(0deg);
    }
    50% {
        transform: rotate(360deg) translateY(-5px);
    }
    100% {
        transform: rotate(720deg);
    }
}

@keyframes float {
    0% {

        transform: translatey(0px);
    }

    50% {
        transform: translatey(-20px);
    }

    100% {

        transform: translatey(0px);
    }
}